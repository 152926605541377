import React, { useEffect, useState } from "react";
import client from "../api-clients/apollo-client";
import { toast } from "react-toastify";
import { gql } from "@apollo/client";
import Select from "react-select";
interface Incoming {
  setstep: (key: any) => void;
  setprofession: (key: any) => void;
  pressKitEmailId: string;
  bio: string;
  setpressKitEmailId: (key: any) => void;
  setbio: (key: any) => void;
  submitprofile: (e: React.SyntheticEvent) => void;
  setmanagers: (e: any) => void;
  setsocialLink: (e: any) => void;
}
interface option {
  value: string;
  label: string;
}
const TalentForm = (props: Incoming) => {
  const handleSubmit = (e: React.SyntheticEvent) => {
    props.setmanagers(managers);
    props.setsocialLink(socialLink);
    props.submitprofile(e);
  };
  const [category, setcategory] = useState<Array<option>>([]);
  const handleChange = (selectedOption: any) => {
    props.setprofession(selectedOption.map((e: option) => e.value));
  };
  const [managers, setmanagers] = useState([
    {
      label: "",
      name: "",
      number: "",
    },
  ]);
  const [socialLink, setsocialLink] = useState([{ label: "", url: "" }]);
  const addCLickSocialLink = () => {
    setsocialLink([...socialLink, { label: "", url: "" }]);
  };
  const removeClickSocialLink = (i: any) => {
    let users = [...socialLink];
    users.splice(i, 1);
    setsocialLink(users);
  };

  const handleChangeSocialLink = (i: any, e: any) => {
    const { name, value } = e.target;
    let users = [...socialLink];
    users[i] = { ...socialLink[i], [name]: value };
    setsocialLink(users);
  };
  const addClickManagers = () => {
    setmanagers([
      ...managers,
      {
        label: "",
        name: "",
        number: "",
      },
    ]);
  };
  const removeClickManagers = (i: any) => {
    let users = [...managers];
    users.splice(i, 1);
    setmanagers(users);
  };
  const handleChangeManagers = (i: any, e: any) => {
    const { name, value } = e.target;
    let users = [...managers];
    users[i] = { ...managers[i], [name]: value };
    setmanagers(users);
  };
  useEffect(() => {
    client
      .query({
        query: gql`
          query {
            Categories {
              parent
              parent_title
              _id
              title
              description
              order
              popular
            }
          }
        `,
      })
      .then((resp) => {
        let newOptions: option[] = [];
        for (let index = 0; index < resp.data.Categories.length; index++) {
          const element: {
            parent: string;
            parent_title: string;
            _id: string;
            title: string;
            description: string;
            order: string;
            popular: string;
          } = resp.data.Categories[index];
          newOptions.push({
            value: element._id,
            label: element.title,
          });
        }
        setcategory(newOptions);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
      });
  }, []);
  return (
    <form className="row" id="mainForm" onSubmit={handleSubmit}>
      <div
        className="col-lg d-lg-flex flex-lg-column justify-content-center align-items-stretch pt-5 pt-lg-0 order-1 order-lg-1"
        data-aos=""
      >
        <div>
          <h2 style={{ lineHeight: "30px" }}>Talent Registration</h2>

          <div className="row">
            <div className="contact col-lg-8">
              <h2 style={{ color: "#2e7ce0" }}>4/4</h2>
              <div
                style={{ marginTop: "-10px" }}
                className="php-email-form1 aos-init aos-animate"
                data-aos="fade-up"
              >
                <div>
                  <div className="form-group mt-3">
                    <p>Profession</p>
                    <Select
                      isClearable
                      isMulti
                      isSearchable
                      onChange={handleChange}
                      options={category}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <p>Connect The Relevant</p>
                    {managers.map((el, i) => (
                      <div
                        key={i}
                        className="d-flex align-items-center flex-wrap my-1"
                      >
                        <input
                          placeholder={`Label ${i + 1}`}
                          name="label"
                          value={el.label || ""}
                          onChange={(e) => handleChangeManagers(i, e)}
                          required={true}
                          list="Label"
                        />
                        <datalist id="Label">
                          <option value="Show & Events" />
                          <option value="Recordings" />
                          <option value="Endorsements" />
                          <option value="Social Media Management" />
                          <option value="Public Relations" />
                        </datalist>
                        <input
                          placeholder={`Name ${i + 1}`}
                          name="name"
                          value={el.name || ""}
                          onChange={(e) => handleChangeManagers(i, e)}
                          required={true}
                        />

                        <input
                          placeholder={`Mobile ${i + 1}`}
                          name="number"
                          value={el.number || ""}
                          required={true}
                          onChange={(e) => handleChangeManagers(i, e)}
                        />

                        {managers.length !== 1 && (
                          <i
                            onClick={() => removeClickManagers(i)}
                            className="fa fa-trash m-1"
                            aria-hidden="true"
                            style={{ cursor: "pointer" }}
                          ></i>
                        )}
                        {managers.length - 1 === i && (
                          <i
                            className="fa fa-plus m-1"
                            aria-hidden="true"
                            style={{ cursor: "pointer" }}
                            onClick={addClickManagers}
                          ></i>
                        )}
                      </div>
                    ))}
                  </div>

                  <div className="form-group mt-3">
                    <p>Bio</p>
                    <input
                      type="text"
                      className="form-control"
                      name="Bio"
                      value={props.bio}
                      onChange={(e) => {
                        props.setbio(e.target.value);
                      }}
                      required={true}
                    />
                  </div>

                  <div className="form-group">
                    <p>social link</p>
                    {socialLink.map((el, i) => (
                      <div
                        key={i}
                        className="d-flex align-items-center flex-wrap my-1"
                      >
                        <input
                          placeholder={`Label ${i + 1}`}
                          name="label"
                          value={el.label || ""}
                          onChange={(e) => handleChangeSocialLink(i, e)}
                          required={true}
                          list="label"
                        />

                        <datalist id="label">
                          <option value="Website" />
                          <option value="Facebook" />
                          <option value="Instagram" />
                          <option value="Twitter" />
                          <option value="Youtube" />
                          <option value="Snapchat" />
                          <option value="Wikipedia" />
                        </datalist>
                        <input
                          placeholder={`Url ${i + 1}`}
                          name="url"
                          value={el.url || ""}
                          onChange={(e) => handleChangeSocialLink(i, e)}
                          required={true}
                        />

                        {socialLink.length !== 1 && (
                          <i
                            onClick={() => removeClickSocialLink(i)}
                            className="fa fa-trash m-1"
                            aria-hidden="true"
                            style={{ cursor: "pointer" }}
                          ></i>
                        )}
                        {socialLink.length - 1 === i && (
                          <i
                            className="fa fa-plus m-1"
                            aria-hidden="true"
                            style={{ cursor: "pointer" }}
                            onClick={addCLickSocialLink}
                          ></i>
                        )}
                      </div>
                    ))}
                  </div>
                  <div className="form-group">
                    <p>Press kit email id</p>
                    <input
                      type="email"
                      name="pressKitEmailId"
                      className="form-control"
                      required={true}
                      value={props.pressKitEmailId}
                      onChange={(e) => {
                        props.setpressKitEmailId(e.target.value);
                      }}
                    />
                  </div>
                  <div className="d-lg-flex justify-content-between">
                    <button type="submit">Submit!</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default TalentForm;
