import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import TalentForm from "./TalentForm";
import ClientForm from "./ClientForm";
import ManagerForm from "./ManagerForm";
import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import Step3 from "./steps/Step3";
export const EarlyAcess = () => {
  const baseUrl = `https://connecttherelevant.com:3301`;
  const [userType, setUserType] = useState("");
  const [step, setstep] = useState(1);
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [contact_no, setcontact_no] = useState("");
  const [otp, setotp] = useState("");
  const [occupation, setOccupation] = useState("");
  const [company, setCompany] = useState("");
  const [designation, setDesignation] = useState("");
  const [linkedin, setlinkedin] = useState("");
  const [website, setwebsite] = useState("");
  const [instagram, setinstagram] = useState("");
  const [profession, setprofession] = useState([]);
  const [pressKitEmailId, setpressKitEmailId] = useState("");
  const [bio, setbio] = useState("");
  const [managers, setmanagers] = useState([]);
  const [socialLink, setsocialLink] = useState([]);
  const [managedTalents, setmanagedTalents] = useState([]);
  const [_id, setId] = useState("");
  const [isMobile, setIsMobile] = useState(false);

  const [loading, setloading] = useState(false);
  const checkMobile = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    checkMobile(); // Check on component mount

    // Add a listener for window resize
    window.addEventListener("resize", checkMobile);

    // Clean up the listener when the component unmounts
    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  }, []);
  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    console.log(userType);
    if (userType === "01") {
      setstep(2);
      return "talent";
    } else if (userType === "02") {
      setstep(2);
      return "manager";
    } else if (userType === "03") {
      setstep(2);
      return "client";
    } else {
      toast.error("please select valid userType");
    }
  };
  const sendOtp = async (e: React.SyntheticEvent) => {
    try {
      e.preventDefault();
      setloading(true);
      //
      let { data } = await axios.post(`${baseUrl}/api/v1/earlyaccess`, {
        name,
        email,
        contact_no,
        userType,
      });
      if (data.status) {
        setId(data.data._id);
        toast.success(`otp sent successfully on ${contact_no}`);
        setstep(3);
      } else {
        toast.error(data.message);
      }
      setloading(false);
    } catch (error: any) {
      setloading(false);
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        console.error(error);
        toast.error(error.message);
      }
    }
  };
  const verifyOtp = async (e: React.SyntheticEvent) => {
    try {
      e.preventDefault();
      let { data } = await axios.post(
        `${baseUrl}/api/v1/earlyaccess/verifyOtp`,
        {
          otpCode: otp,
          _id,
        }
      );
      console.log(data);
      toast.success("otp verifed success");
      setstep(4);
    } catch (error: any) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        console.error(error);
        toast.error(error.message);
      }
    }
  };
  const resetAllState = () => {
    setname("");
    setemail("");
    setCompany("");
    setcontact_no("");
    setOccupation("");
    setDesignation("");
    setlinkedin("");
    setwebsite("");
    setprofession([]);
    setmanagers([]);
    setsocialLink([]);
    setmanagedTalents([]);
    setstep(1);
    setUserType("");
    setId("");
    setbio("");
    setpressKitEmailId("");
    setotp("");
  };
  const submitprofile = async (e: React.SyntheticEvent) => {
    try {
      e.preventDefault();
      let body = {
        _id,
        name,
        email,
        company,
        contact_no,
        occupation: occupation ? [occupation] : [],
        designation,
        linkedin,
        website,
        about: bio,
        pressKitEmailId,
        professions: profession.length ? profession : [],
        managementConnect: managers,
        socialLink,
        userType,
        managedTalents,
      };

      let { data } = await axios.put(`${baseUrl}/api/v1/earlyaccess`, body);
      //  api call
      resetAllState();
      // reset all State

      console.log(data);
      toast.success("Success");
    } catch (error: any) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        console.error(error);
        toast.error(error.message);
      }
    }
  };

  useEffect(() => {
    document.title = "Early Access";
  }, []);

  return (
    <>
      <section id="hero" className="d-flex align-items-center">
        <div className="container">
          {step === 1 ? (
            <Step1
              isMobile={isMobile}
              setUserType={setUserType}
              handleSubmit={handleSubmit}
            />
          ) : step === 2 ? (
            <Step2
              userType={userType}
              isMobile={isMobile}
              sendOtp={sendOtp}
              setstep={setstep}
              name={name}
              email={email}
              contact_no={contact_no}
              setname={setname}
              setemail={setemail}
              setcontact_no={setcontact_no}
              loading={loading}
            />
          ) : step === 3 ? (
            <Step3
              userType={userType}
              isMobile={isMobile}
              setotp={setotp}
              setstep={setstep}
              verifyOtp={verifyOtp}
              otp={otp}
            />
          ) : userType === "01" ? (
            <TalentForm
              setstep={setstep}
              setpressKitEmailId={setpressKitEmailId}
              pressKitEmailId={pressKitEmailId}
              setprofession={setprofession}
              setbio={setbio}
              submitprofile={submitprofile}
              setmanagers={setmanagers}
              setsocialLink={setsocialLink}
              bio={bio}
            />
          ) : userType === "02" ? (
            <ManagerForm
              setmanagedTalents={setmanagedTalents}
              submitprofile={submitprofile}
              designation={designation}
              occupation={occupation}
              company={company}
              setCompany={setCompany}
              setDesignation={setDesignation}
              setOccupation={setOccupation}
              instagram={instagram}
              linkedin={linkedin}
              website={website}
              setinstagram={setinstagram}
              setwebsite={setwebsite}
              setlinkedin={setlinkedin}
            />
          ) : (
            <ClientForm
              submitprofile={submitprofile}
              designation={designation}
              occupation={occupation}
              company={company}
              setCompany={setCompany}
              setDesignation={setDesignation}
              setOccupation={setOccupation}
            />
          )}
        </div>
      </section>
    </>
  );
};

// :
