import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <>
      <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 footer-links">
                <ul>
                  <li>
                    {" "}
                    <Link to="about-us.html" style={{ color: "#2e7ce0" }}>
                      About us
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="brand-center.html">Brand Center</Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="contact-us.html">Get in touch</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 col-md-6 footer-links">
                <ul>
                  <li>
                    {" "}
                    <Link to="terms-of-service.html">Terms of service</Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="privacy-policy.html">Privacy policy</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 col-md-6 footer-links">
                <div className="social-links mt-3">
                  {" "}
                  <Link to="#" className="twitter">
                    <i className="fab fa-facebook-f"></i>
                  </Link>{" "}
                  <Link to="#" className="facebook">
                    <i className="fab fa-instagram"></i>
                  </Link>{" "}
                  <Link to="#" className="instagram">
                    <i className="fab fa-twitter"></i>
                  </Link>{" "}
                  <Link to="#" className="google-plus">
                    <i className="fab fa-linkedin-in"></i>
                  </Link>{" "}
                  <Link to="#" className="linkedin">
                    <i className="fab fa-youtube"></i>
                  </Link>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container py-4">
          <div className="copyright">
            {" "}
            <span id="copyright">
              <script>
                document.getElementById('copyright').appendChild(document.createTextNode(new
                Date().getFullYear()))
              </script>
            </span>{" "}
            &copy; CONNECT THE RELEVANT.{" "}
          </div>
        </div>
      </footer>

      <Link to="#" className="back-to-top">
        <i className="icofont-simple-up"></i>
      </Link>
    </>
  );
};
