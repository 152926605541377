import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import Home2 from "./components/Home2";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {
  return (
    <>
      <Router>
        <ToastContainer />
        <Routes>
          <Route path="/" Component={Home2} />
          <Route path="/early-access" Component={Home2} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
