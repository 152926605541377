import React, { useState, useEffect } from "react";
interface Incoming {
  setUserType: (key: any) => void;
  handleSubmit: (e: React.SyntheticEvent) => void;
  isMobile: Boolean;
}
const Step1 = (props: Incoming) => {
  return (
    <div className="row">
      <div
        className="col-lg d-lg-flex flex-lg-column justify-content-center align-items-stretch pt-5 pt-lg-0 order-1 order-lg-1"
        data-aos=""
      >
        <div>
          <h2 style={{ lineHeight: "30px" }}>
            CONNECT THE RELEVANT is the Members-Only App that Connects: Talents,
            Their Official Representatives and Industry Professionals.
          </h2>

          <div className="row">
            <div className="contact col-lg-8">
              <h2 style={{ color: "#2e7ce0" }}>Join Early Access!</h2>
              <div
                style={{ marginTop: "-10px" }}
                className="php-email-form1 aos-init aos-animate"
                data-aos="fade-up"
              >
                <form id="mainForm" onSubmit={props.handleSubmit}>
                  <div className="form-group">
                    <p>Talent</p>
                    <input
                      type="radio"
                      name="userType"
                      className="form-control"
                      value={"01"}
                      required={true}
                      onChange={(e) => {
                        props.setUserType(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-group mt-3">
                    <p>Official Representatives</p>
                    <input
                      type="radio"
                      className="form-control"
                      name="userType"
                      value={"02"}
                      required={true}
                      onChange={(e) => {
                        props.setUserType(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-group mt-3">
                    <p>Industry Professionals.</p>
                    <input
                      type="radio"
                      name="userType"
                      className="form-control"
                      value={"03"}
                      required={true}
                      onChange={(e) => {
                        props.setUserType(e.target.value);
                      }}
                    />
                  </div>
                  <div className="">
                    <button type="submit">Next!</button>
                  </div>
                </form>

                <div className="my-3">
                  <div className="loading">Loading</div>
                  <div className="sent-message">
                    You have been successfully added to Early Access. Thank You!
                  </div>
                  <div className="error-message"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!props.isMobile && (
        <div
          className="col-lg d-lg-flex flex-lg-column align-items-stretch order-2 order-lg-2 hero-img"
          data-aos="zoom-out"
          data-aos-delay="300"
        >
          <img
            src="/img/connect.jpg"
            width="1000px"
            className="img-fluid animated"
            alt=""
          />
        </div>
      )}
    </div>
  );
};

export default Step1;
