import React, { useEffect, useState } from "react";
import client from "../api-clients/apollo-client";
import { toast } from "react-toastify";
import { gql } from "@apollo/client";
import Select from "react-select";
interface Incoming {
  submitprofile: (e: React.SyntheticEvent) => void;
  designation: string;
  company: string;
  occupation: string;
  setOccupation: (key: any) => void;
  setCompany: (key: any) => void;
  setDesignation: (key: any) => void;
  linkedin: string;
  setlinkedin: (key: any) => void;
  website: string;
  setwebsite: (key: any) => void;
  instagram: string;
  setinstagram: (key: any) => void;
  setmanagedTalents: (key: any) => void;
}
interface option {
  value: string;
  label: string;
}
const ManagerForm = (props: Incoming) => {
  const [loading, setloading] = useState(false);
  const [occupation, setOccupation] = useState<Array<option>>([]);
  const [talents, setTalents] = useState([{ name: "", contact_no: "" }]);
  const handleChange = (selectedOption: any) => {
    if (selectedOption && selectedOption.value) {
      props.setOccupation(selectedOption.value);
    } else {
      props.setOccupation("");
      console.error(`errro while setting occupation`);
    }
  };
  useEffect(() => {
    setloading(true);
    client
      .query({
        query: gql`
          {
            Occupation {
              name
              _id
            }
          }
        `,
      })
      .then((resp) => {
        let newOptions = [];
        for (let index = 0; index < resp.data.Occupation.length; index++) {
          const element = resp.data.Occupation[index];
          newOptions.push({
            value: element._id,
            label: element.name,
          });
        }
        setOccupation(newOptions);
        setloading(false);
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.message);
        setloading(false);
      });
  }, []);

  const addClick = () => {
    setTalents([...talents, { name: "", contact_no: "" }]);
  };
  const handleChangeTalents = (i: any, e: any) => {
    const { name, value } = e.target;
    let users = [...talents];
    users[i] = { ...talents[i], [name]: value };
    setTalents(users);
  };
  const removeClick = (i: any) => {
    let users = [...talents];
    users.splice(i, 1);
    setTalents(users);
  };
  const handleSubmit = (e: React.SyntheticEvent) => {
    props.setmanagedTalents(talents);
    props.submitprofile(e);
  };

  return (
    <>
      {" "}
      {loading ? (
        <div style={{ minHeight: "300px" }}>
          <center>
            <div
              className="spinner-border"
              style={{ color: "#2e7ce0" }}
              role="status"
            >
              <span className="visually-hidden"></span>
            </div>
          </center>
        </div>
      ) : (
        <form className="row" id="mainForm" onSubmit={handleSubmit}>
          <div
            className="col-lg d-lg-flex flex-lg-column justify-content-center align-items-stretch pt-5 pt-lg-0 order-1 order-lg-1"
            data-aos=""
          >
            <div>
              <h2 style={{ lineHeight: "30px" }}>
                Official Representative Registration
              </h2>

              <div className="row">
                <div className="contact col-lg-8">
                  <h2 style={{ color: "#2e7ce0" }}>4/4</h2>
                  <div
                    style={{ marginTop: "-10px" }}
                    className="php-email-form1 aos-init aos-animate"
                    data-aos="fade-up"
                  >
                    <div>
                      <div className="form-group">
                        <p>Occupation</p>
                        <Select
                          isClearable
                          isSearchable
                          onChange={handleChange}
                          options={occupation}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <p>Designation</p>
                        <input
                          type="text"
                          name="Designation"
                          className="form-control"
                          value={props.designation}
                          onChange={(e) => {
                            props.setDesignation(e.target.value);
                          }}
                          required={true}
                        />
                      </div>
                      <div className="form-group">
                        <p>Company Name</p>
                        <input
                          type="text"
                          name="Company Name"
                          className="form-control"
                          value={props.company}
                          onChange={(e) => {
                            props.setCompany(e.target.value);
                          }}
                          required={true}
                        />
                      </div>
                    </div>

                    <div className="my-3">
                      <div className="loading">Loading</div>
                      <div className="sent-message">
                        You have been successfully added to Early Access. Thank
                        You!
                      </div>
                      <div className="error-message"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-lg d-lg-flex flex-lg-column align-items-stretch order-2 order-lg-2 hero-img"
            data-aos="zoom-out"
            data-aos-delay="300"
          >
            {/* //todo 2nd form
            linkedin 
            instagram
            website

            */}
            <div>
              <div className="row">
                <div className="contact col-lg-8">
                  <div
                    style={{ marginTop: "-10px" }}
                    className="php-email-form1 aos-init aos-animate"
                    data-aos="fade-up"
                  >
                    <div>
                      <div className="form-group">
                        <p>Linkedin</p>
                        <input
                          type="text"
                          name="linkedin"
                          className="form-control"
                          value={props.linkedin}
                          onChange={(e) => {
                            props.setlinkedin(e.target.value);
                          }}
                          required={true}
                        />
                      </div>
                      <div className="form-group">
                        <p>Instagram</p>
                        <input
                          type="text"
                          name="instagram"
                          className="form-control"
                          value={props.instagram}
                          onChange={(e) => {
                            props.setinstagram(e.target.value);
                          }}
                          required={true}
                        />
                      </div>
                      <div className="form-group">
                        <p>Website</p>
                        <input
                          type="text"
                          name="Website"
                          className="form-control"
                          value={props.website}
                          onChange={(e) => {
                            props.setwebsite(e.target.value);
                          }}
                          required={true}
                        />
                      </div>
                      <div className="form-group">
                        <p>Managed Talents</p>
                        {talents.map((el, i) => (
                          <div key={i} className="d-flex align-items-center">
                            <input
                              placeholder={`Name ${i + 1}`}
                              name="name"
                              value={el.name || ""}
                              onChange={(e) => handleChangeTalents(i, e)}
                              required={true}
                            />

                            <input
                              placeholder={`Mobile ${i + 1}`}
                              name="contact_no"
                              value={el.contact_no || ""}
                              required={true}
                              onChange={(e) => handleChangeTalents(i, e)}
                            />
                            {talents.length - 1 === i && (
                              <i
                                onClick={() => addClick()}
                                className="fa fa-plus mx-1"
                                aria-hidden="true"
                                style={{ cursor: "pointer" }}
                              ></i>
                            )}
                            {talents.length !== 1 && (
                              <i
                                onClick={() => removeClick(i)}
                                className="fa fa-trash mx-1"
                                aria-hidden="true"
                                style={{ cursor: "pointer" }}
                              ></i>
                            )}
                          </div>
                        ))}
                      </div>

                      <div className="d-lg-flex justify-content-between">
                        {/* <button
                          className="primaryButton"
                          type="button"
                          onClick={addClick}
                        >
                          add more
                        </button> */}
                        <button type="submit">Submit!</button>
                      </div>
                    </div>

                    <div className="my-3">
                      <div className="loading">Loading</div>
                      <div className="sent-message">
                        You have been successfully added to Early Access. Thank
                        You!
                      </div>
                      <div className="error-message"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default ManagerForm;
