import { Header } from "./Header";
import { useEffect } from "react";
import { Footer } from "./Footer";

import { EarlyAcess } from "./EarlyAcess";

const Home = () => {
  useEffect(() => {}, []);

  return (
    <>
      <Header />
      <main id="main">
        <EarlyAcess />
      </main>
      <Footer />
    </>
  );
};

export default Home;
