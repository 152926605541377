import React from "react";
interface Incoming {
  setstep: (key: any) => void;
  sendOtp: (e: React.SyntheticEvent) => void;
  setname: (key: any) => void;
  setemail: (key: any) => void;
  setcontact_no: (key: any) => void;
  name: string;
  contact_no: string;
  email: string;
  isMobile: Boolean;
  userType: String;
  loading: Boolean;
}
const Step2 = (props: Incoming) => {
  return (
    <div className="row">
      <div
        className="col-lg d-lg-flex flex-lg-column justify-content-center align-items-stretch pt-5 pt-lg-0 order-2 order-lg-1"
        data-aos=""
      >
        <div>
          <h2 style={{ lineHeight: "30px" }}>
            {props.userType === "01"
              ? "Talent"
              : props.userType === "02"
              ? "Official Representative"
              : "Industry Professional"}{" "}
            Registration
          </h2>

          <div className="row">
            <div className="contact col-lg-8">
              <h2 style={{ color: "#2e7ce0" }}>2/4</h2>
              <div
                style={{ marginTop: "-10px" }}
                className="php-email-form1 aos-init aos-animate"
                data-aos="fade-up"
              >
                <form id="mainForm" onSubmit={props.sendOtp}>
                  <div className="form-group">
                    <p>Name</p>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      value={props.name}
                      onChange={(e) => {
                        props.setname(e.target.value);
                      }}
                      required={true}
                    />
                  </div>
                  <div className="form-group mt-3">
                    <p>Email</p>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      value={props.email}
                      onChange={(e) => {
                        props.setemail(e.target.value);
                      }}
                      required={true}
                    />
                  </div>
                  <div className="form-group mt-3">
                    <p>Mobile Number</p>
                    <input
                      type="text"
                      name="contact_no"
                      className="form-control"
                      maxLength={10}
                      value={props.contact_no}
                      onChange={(e) => props.setcontact_no(e.target.value)}
                      required={true}
                    />
                  </div>
                  {!props.loading  ? (
                    <div className="d-flex justify-content-between">
                      <button
                        type="button"
                        className="primaryButton"
                        onClick={() => {
                          props.setstep(1);
                        }}
                        style={{ color: "white" }}
                      >
                        Back!
                      </button>
                      <button type="submit">Next!</button>
                    </div>
                  ) : (
                    <div style={{ minHeight: "300px" }}>
                      <center>
                        <div
                          className="spinner-border"
                          style={{ color: "#2e7ce0" }}
                          role="status"
                        >
                          <span className="visually-hidden"></span>
                        </div>
                      </center>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!props.isMobile && (
        <div
          className="col-lg d-lg-flex flex-lg-column align-items-stretch order-1 order-lg-2 hero-img"
          data-aos="zoom-out"
          data-aos-delay="300"
        >
          <img
            src="/img/connect.jpg"
            width="1000px"
            className="img-fluid animated"
            alt=""
          />
        </div>
      )}
    </div>
  );
};

export default Step2;
